
import { defineComponent } from 'vue'
import { Form } from 'vee-validate'
import * as yup from 'yup'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'CreateBuildingSection',
  data () {
    const setBuildinCreate = yup.object().shape({})

    return {
      message: '',
      setBuildinCreate,
      center: { lat: 52.53230758079442, lng: 13.412759361633627 },
      markers: { lat: 52.53230758079442, lng: 13.412759361633627 },
      address: 'Country, City, street, house',
      initProp: null,
      coordinates: null,
      coordinatesAuto: {
        lat: null,
        lng: null
      },
      coordinates_2: null,
      buildingId: null,
      errorBuilding: null,
      addressData: null,
      city: null,
      country: 'DE',
      countryСode: 'DE',
      house: null,
      street: null,
      buildingData: {},
      findAddress: true,
      setLat: '',
      setLng: '',
      addressLabel: 'Address',
      cardMapOpen: false,
      errorCreateBuilding: null,
      zipCode: null,
      isLoading: true
    }
  },
  components: {
    Form,
    AlertBlock,
    LottieAnimation: Vue3Lottie
  },
  mounted () {
    const lang = JSON.parse(localStorage.getItem('lang'))
    this.activeLang = lang
    this.initPropId = this.$route.query.buildingId
    if (this.initPropId) {
      this.getBuilding(this.initPropId)
    }
    if (this.initPropId === undefined) {
      this.createBuilding()
    }
    if (this.initPropId) {
      this.buildingId = this.initPropId
      this.draftBuilding(this.buildingId)
    }
  },
  methods: {
    getAddressData (addressData) {
      this.setLat = addressData.geometry.location.lat()
      this.setLng = addressData.geometry.location.lng()
      for (let i = 0; i < addressData.address_components.length; i++) {
        if (addressData.address_components[i].types[0] === 'locality') {
          this.city = addressData.address_components[i].long_name
        }
        if (addressData.address_components[i].types[0] === 'route') {
          this.street = addressData.address_components[i].long_name
        }
        if (addressData.address_components[i].types[0] === 'street_number') {
          this.house = addressData.address_components[i].long_name
        }
        if (addressData.address_components[i].types[0] === 'country') {
          this.country = addressData.address_components[i].long_name
          this.countryСode = addressData.address_components[i].short_name
        }
        if (addressData.address_components[i].types[0] === 'postal_code') {
          this.zipCode = addressData.address_components[i].long_name
        }
      }
      this.addMarker()
      this.addressLabel = addressData.formatted_address
    },
    getBuilding (data) {
      this.$store.dispatch('property/getBuilding', data).then(
        (res) => {
          this.buildingData = res.data
          this.isLoading = false
        },
        (error) => {
          this.error = error
          this.isLoading = false
        }
      )
    },
    createBuilding () {
      this.$store.dispatch('property/createBuilding').then(
        (res) => {
          this.buildingId = res.data.id
          this.draftBuilding(this.buildingId)
          this.$router.push({ query: { buildingId: this.buildingId } })
        },
        (error) => {
          this.error = error.response.data
        }
      )
    },
    draftBuilding (buildingId) {
      this.$store.dispatch('property/draftBuilding', buildingId).then(
        (res) => {
          this.buildingData = res.data
          if (this.buildingData.draft.full_address) {
            this.cardMapOpen = true
            this.addressLabel = `${this.buildingData.draft?.country}, ${this.buildingData.draft.city}, ${this.buildingData.draft.street} ${this.buildingData.draft.house}`
            if (this.buildingData.draft == null) {
              this.markers.lat = +this.buildingData?.coordinates.split(',')[0]
              this.center.lat = +this.buildingData?.coordinates.split(',')[0]
              this.markers.lng = +this.buildingData?.coordinates.split(',')[1]
              this.center.lng = +this.buildingData?.coordinates.split(',')[1]
            } else {
              if (this.buildingData.draft.coordinates != null) {
                this.markers.lat = +this.buildingData?.draft?.coordinates_2.split(',')[0]
                this.center.lat = +this.buildingData?.draft?.coordinates_2.split(',')[0]
                this.markers.lng = +this.buildingData?.draft?.coordinates_2.split(',')[1]
                this.center.lng = +this.buildingData?.draft?.coordinates_2.split(',')[1]
                this.coordinatesAuto.lat = +this.buildingData?.draft?.coordinates_2.split(',')[0]
                this.coordinatesAuto.lng = +this.buildingData?.draft?.coordinates_2.split(',')[1]
              }
            }
            this.selectAddress()
          }
          this.isLoading = false
        },
        (error) => {
          this.error = error.response.data.message
          this.isLoading = false
        }
      )
    },
    createBuildingStepOne (data) {
      if (this.city == null) {
        this.countryСode = this.buildingData.draft.country_code
        this.country = this.buildingData.draft.country
        this.house = this.buildingData.draft.house
        this.street = this.buildingData.draft.street
        this.city = this.buildingData.draft.city
        this.zipCode = this.buildingData.draft.zip_code
      }
      data = {
        url: this.buildingId,
        coordinates: this.coordinatesAuto.lat.toString().substr(0, 10) + ',' + this.coordinatesAuto.lng.toString().substr(0, 10),
        coordinates_2: this.markers.lat.toString().substr(0, 10) + ',' + this.markers.lng.toString().substr(0, 10),
        country: this.country,
        country_code: this.countryСode,
        city: this.city,
        street: this.street,
        house: this.house,
        zip_code: this.zipCode
      }
      this.$store.dispatch('property/saveBuildingFirstStep', data).then(
        () => {
          this.$router.push(`/dashboard/create-building-step-two?buildingId=${this.buildingId}`)
        },
        (error) => {
          this.errorBuilding = error.response.data.errors
        }
      )
    },
    handleDiscard () {
      this.$store.dispatch('property/discardBuilding', this.buildingId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.errorBuilding = error.response.data.errors
        }
      )
    },
    onMarkerDragEnd (event) {
      this.markers = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      this.coordinates_2 = this.markers
    },
    async addMarker () {
      const marker = {
        lat: this.setLat,
        lng: this.setLng
      }
      this.coordinates = `${this.setLat},${this.setLng}`
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.setLat},${this.setLng}&key=AIzaSyAZ6mJgUpdJNO_WpyynnB-ZTMqFmgKYLJc`
        )
        const res = await response.json()
        this.address = res.results
        this.addressData = res.results[0].address_components
        for (let i = 0; i < this.addressData.length; i++) {
          if (this.addressData[i].types[0] === 'locality') {
            this.city = this.addressData[i].long_name
          }
          if (this.addressData[i].types[0] === 'route') {
            this.street = this.addressData[i].long_name
          }
          if (this.addressData[i].types[0] === 'street_number') {
            this.house = this.addressData[i].long_name
          }
          if (this.addressData[i].types[0] === 'country') {
            this.country = this.addressData[i].long_name
          }
          if (this.addressData[i].types[0] === 'country') {
            this.countryCode = this.addressData[i].short_name
          }
        }
        console.log(this.addressData, this.city, 'this.addressData')
        this.markers = marker
        this.coordinatesAuto = this.markers
        this.$refs.mmm.panTo(marker)
      } catch (error) {
        console.error(error)
      }
    },
    selectAddress () {
      this.findAddress = true
    },
    openMap () {
      this.cardMapOpen = true
    },
    changeAddress () {
      this.editTrigger = true
      this.cardMapOpen = false
      this.errorBuilding = null
    },
    deleteBuilding () {
      this.$store.dispatch('property/deleteBuilding', this.buildingId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.errorDeleteBuilding = error.response.data.errors
        }
      )
    }
  }
})
